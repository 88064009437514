import React, { useState, useEffect, useCallback } from "react";
import useEmblaCarousel, { EmblaOptionsType } from "embla-carousel-react";
import Image from "next/image";
import {
  DotButton,
  PrevButton,
  NextButton,
} from "./EmblaCarouselArrowsDotsButtons";
import Autoplay from "embla-carousel-autoplay";
import AutoHeight from "embla-carousel-auto-height";
import ImagebyBanner from "@/pages/ImagebyBanner";
import Link from "next/link";

type PropType = {
  slides: number[];
  options?: EmblaOptionsType;
  data: any;
};

const EmblaCarousel: React.FC<PropType> = (props) => {
  const { slides, options, data } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    Autoplay(),
    AutoHeight(),
  ]);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const url = process.env.NEXT_PUBLIC_BASE_URL_API;
  const [images, setImages] = useState([]);
  // const [slides, setSlides] = useState([]);

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );
  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on("select", onSelect);
    emblaApi.on("reInit", onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  return (
    <>
      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {slides.map((index) => {
              if (data[index].url == null || data[index].url == "") {
                return (
                  <div className="embla__slide" key={index}>
                    <Image
                      className="embla__slide__img"
                      src={data[index].file}
                      alt={data[index].topic}
                      width={100}
                      height={100}
                    />
                  </div>
                );
              } else {
                return (
                  <div
                    className="embla__slide"
                    key={index}
                    style={{ position: "relative" }}
                  >
                    <a
                      href={`${data[index].url}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        className="embla__slide__img"
                        src={data[index].file}
                        alt={data[index].topic}
                        width={100}
                        height={100}
                      />
                    </a>
                  </div>
                );
              }
            })}
          </div>
        </div>

        <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
      </div>

      <div className="embla__dots">
        {scrollSnaps.map((_, index) => (
          <DotButton
            key={index}
            selected={index === selectedIndex}
            onClick={() => scrollTo(index)}
          />
        ))}
      </div>
    </>
  );
};

export default EmblaCarousel;
