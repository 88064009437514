import React, { useEffect } from "react";
import { NextPage } from "next/types";
import { wrapper } from "@/store/store";
import Header from "@/components/Header";
import Menu from "@/components/Menu";
import News from "@/components/News";
import TableAllUnfinsh from "@/components/TableAllUnfinsh";
import TableMyUnfinsh from "@/components/TableMyUnfinsh";
import TableRanking from "@/components/TableRanking";
import EmblaCarousel from "@/components/EmblaCarousel";
import { EmblaOptionsType } from "embla-carousel-react";
import Link from "next/link";
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import LanguageIcon from "@mui/icons-material/Language";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

import ScrollToTopFab from "@/components/ScrollToTopFab";
import Footer from "@/components/Footer";
import axios from "axios";
import { setCookie, getCookie, deleteCookie, getCookies } from "cookies-next";

import withAuth from "@/components/withAuth";
import { appDispatch, appSelector } from "@/store/hooks";
import { getMyUnfinish } from "@/features/my_unfinish";
const OPTIONS: EmblaOptionsType = { loop: true };

const Index = ({
  banner,
  news,
  unfinishProjectAll,
  myUnfinishProject,
  ranking,
  token,
}: any) => {
  const SLIDE_DATA = banner.data;
  const SLIDE_COUNT = banner.total;
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys());
  const NEWS_DATA = news.data;

  const SLIDE_UNFINISH_PROJECT_ALL = unfinishProjectAll.data;
  const SLIDE_RANKING = ranking.data;

  const TOKEN = token;

  const { data } = appSelector((state) => state.myUnfinish);
  const rowsmyUnFinishData: any = data ?? [];
  const SLIDE_MY_UNFINISH_PROJECT = rowsmyUnFinishData;
  const dispatch = appDispatch();
  useEffect(() => {
    dispatch(getMyUnfinish());
  }, [dispatch]);
  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="container">
        <div className="row">
          <section className="carousel">
            <EmblaCarousel
              slides={SLIDES}
              options={OPTIONS}
              data={SLIDE_DATA}
            />
          </section>
        </div>
        <div className="row mb-5">
          <div className="col-12 Heading_main mb-3">
            <h4 className="fw-bold heading" id="back-to-top-anchor">
              News
            </h4>
          </div>
          <div className="col-12 box_main_news mb-3">
            <News data={NEWS_DATA} />
          </div>
          <div className="col-12 my-4 text-center box_main_news">
            <Link href="/newsall">
              <Button sx={{ px: 5, py: 1.5 }} variant="contained">
                View more
              </Button>
            </Link>
          </div>
        </div>
        <div className="row  align-items-center">
          <div className="col-12 mt-2 mb-3">
            <Box
              sx={{
                pb: 2,
                mb: 2,
                borderBottom: "1px solid #0b308e",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                noWrap
                sx={{
                  ml: 0,
                  mb: 0,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  letterSpacing: "0",
                  color: "#000",
                  textDecoration: "none",
                }}
              >
                <LanguageIcon
                  sx={{ fontSize: "22px", mr: 1 }}
                  color="primary"
                />{" "}
                1. Unfinished Project ( ALL )
              </Typography>
            </Box>
          </div>
          <div className="col-12">
            <TableAllUnfinsh data={SLIDE_UNFINISH_PROJECT_ALL} />
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-12 mt-2 mb-3">
            <Box
              sx={{
                pb: 2,
                mb: 2,
                borderBottom: "1px solid #0b308e",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                noWrap
                sx={{
                  ml: 0,
                  mb: 0,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  letterSpacing: "0",
                  color: "#000",
                  textDecoration: "none",
                }}
              >
                <AccountCircleIcon
                  color="secondary"
                  sx={{ fontSize: "22px", mr: 1 }}
                />{" "}
                2. My Unfinished Project
              </Typography>
            </Box>
          </div>
          <div className="col-12">
            <TableMyUnfinsh
              data={SLIDE_MY_UNFINISH_PROJECT}
              token={TOKEN}
              myDispatch={getMyUnfinish()}
            />
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-12 mt-2 mb-3">
            <Box
              sx={{
                pb: 2,
                mb: 2,
                borderBottom: "1px solid #0b308e",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                noWrap
                sx={{
                  ml: 0,
                  mb: 0,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  letterSpacing: "0",
                  color: "#000",
                  textDecoration: "none",
                }}
              >
                <EmojiEventsIcon
                  sx={{ fontSize: "22px", mr: 1, color: "rgb(255,193,7)" }}
                />{" "}
                3. The number of project ( Ranking )
              </Typography>
            </Box>
          </div>
          <div className="col-12">
            <TableRanking data={SLIDE_RANKING} />
          </div>
        </div>
      </div>

      <ScrollToTopFab />
      <Footer />
    </div>
  );
};

export async function getServerSideProps(context: any) {
  const url = process.env.NEXT_PUBLIC_BASE_URL_API;
  let token = context.req.cookies["access-token-front"];
  if (!token) {
    token = null;
  }
  const fetchBanner = await fetch(`${url}/banner/select/show`);
  const banner = await fetchBanner.json();

  const fetchNews = await fetch(`${url}/news/select/show`);
  const news = await fetchNews.json();

  const fetchUnfinishProjectAll = await fetch(`${url}/unfinished-project-all`, {
    headers: {
      "access-token-front": `Bearer ${token}`,
    },
  });
  let unfinishProjectAll = await fetchUnfinishProjectAll.json();
  if (!unfinishProjectAll) {
    unfinishProjectAll = [];
  }

  const fetchRanking = await fetch(`${url}/ranking`, {
    headers: {
      "access-token-front": `Bearer ${token}`,
    },
  });
  let ranking = await fetchRanking.json();
  if (!ranking) {
    ranking = [];
  }
  return {
    props: {
      banner: {
        total: banner.data.length,
        data: banner.data,
      },
      news: {
        total: news.data.length,
        data: news.data,
      },
      unfinishProjectAll: unfinishProjectAll,
      ranking: ranking,
      token: token,
    },
  };
}

export default withAuth(Index);
