import React from 'react';
import PropTypes from 'prop-types';
import CopyrightIcon from '@mui/icons-material/Copyright';


const Footer = () => {
    return (
        <footer>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-12 py-3 text-center'>
                        <p className='mb-0'>Copyright <CopyrightIcon/> 2023 OKUMA TECHNO (THAILAND) LTD. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}


export default Footer;