import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import Link from "next/link";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { red } from "@mui/material/colors";
import axios from "axios";
import Swal from "sweetalert2";
import router from "next/router";
import { appDispatch, appSelector } from "@/store/hooks";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(11, 48, 142,0.9)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "rgb(231, 235, 240)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableMyUnfinsh = ({ data, token, myDispatch }: any) => {
  const dispatch = appDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const Delete = (id: any, token: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        const url = process.env.NEXT_PUBLIC_BASE_URL_API;
        const response: any = await axios.delete(
          `${url}/my-information/delete?id=${id}`,
          {
            headers: {
              "access-token-front": `Bearer ${token}`,
            },
          }
        );
        if (response.data.status == "success") {
          if (response.data.status == "success") {
            Swal.fire("Success!", "Your data has been deleted", "success").then(
              function () {
                // router.reload();
                dispatch(myDispatch);
              }
            );
          } else {
            Swal.fire("Error!", "", "error").then(function () {
              return false;
            });
          }
        }
      }
    });
  };
  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={{ width: "100%", overflow: "hidden", mb: 5 }}
    >
      <TableContainer>
        <Table
          sx={{ minWidth: 700 }}
          stickyHeader
          aria-label="sticky customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" sx={{ minWidth: 30 }}>
                No.
              </StyledTableCell>
              <StyledTableCell align="center">Report No.</StyledTableCell>
              <StyledTableCell align="center">Serial No.</StyledTableCell>
              <StyledTableCell align="center">Client Name</StyledTableCell>
              <StyledTableCell align="center" sx={{ minWidth: 100 }}>
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {data.map((row: any, index: any) => { */}
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any, index: any) => {
                return (
                  <TableRow hover key={index}>
                    <StyledTableCell align="center">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.report_no}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.serial_no}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.client_name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <div className="d-flex align-items-center justify-content-center">
                        <Link
                          href={`/MyUnFinishProject?report=${row.report_no}`}
                        >
                          <Tooltip title="View">
                            <IconButton>
                              <VisibilityIcon
                                fontSize="small"
                                color="primary"
                              />
                            </IconButton>
                          </Tooltip>
                        </Link>
                        <div className="vr"></div>
                        <Link
                          href={`/EditMyUnFinishProject?report=${row.report_no}`}
                        >
                          <Tooltip title="Edit">
                            <IconButton>
                              <EditIcon fontSize="small" color="primary" />
                            </IconButton>
                          </Tooltip>
                        </Link>
                        <div className="vr"></div>
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => {
                              Delete(row.report_id, token);
                            }}
                          >
                            <DeleteIcon
                              fontSize="small"
                              sx={{ color: red[500] }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default TableMyUnfinsh;
