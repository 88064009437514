import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Link from "next/link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const News = (props: any) => {
  const { data } = props;

  return (
    <div className="box_news-inner">
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 1 }}
          columns={{ xs: 12, sm: 12, md: 12 }}
          alignItems="center"
        >
          {data.map((value: any, index: any) => {
            const dmy = value.dmy;
            const topicURI = encodeURI(value.topic);
            const topic = value.topic;
            const id = value.news_id;
            if (index < 5) {
              return (
                <Grid xs={12} sm={12} md={12} key={index}>
                  <Link href={`/NewsDetail/${id}/${topic}`}>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 2, sm: 3, md: 3 }}
                      className="news-inner"
                    >
                      <div className="news-date col-12 col-sm-2">{dmy}</div>
                      <div className="news-subject col-12 col-sm-8 ms-0">
                        {topic}
                      </div>
                      <div className="link-more col-12 col-sm-2 ms-0 text-end">
                        Read more <NavigateNextIcon />
                      </div>
                    </Stack>
                  </Link>
                </Grid>
              );
            }
          })}
        </Grid>
      </Box>
    </div>
  );
};

export default News;
