import React from "react";
import { useSelector } from "react-redux";
import Router, { useRouter } from "next/router";
import { appSelector, appDispatch } from "@/store/hooks";
import { getSession } from "@/features/login";

const withAuth = (WrappedComponent: React.FC) => (props: any) => {
  const router = useRouter();
  const { route } = router;
  const { data, isAuthenticated, isAuthenticating } = appSelector(
    (state: any) => state.login_front
  );
  const dispatch = appDispatch();

  if (isAuthenticating) {
    dispatch(getSession());
    return null;
  }

  if (route !== "/login" && route !== "/register") {
    if (!isAuthenticated) {
      router.push(`/login`);
      return null;
    }
  } else {
    if (isAuthenticated) {
      router.push(`/`); // default page after login
      return null;
    }
  }

  // if (route == "/") {
  //   if (!isAuthenticated) {
  //     router.push(`/login`);
  //     return null;
  //   }
  // }
  // If user is logged in, return original component
  // if (data) {
  //   router.push(`/login`);
  //   return;
  // }

  return (
    <WrappedComponent
      {...props}
      user_id={data.data.user_id}
      token={data.data.token}
    />
  );

  return null;
};

export default withAuth;
